export default {
  data() {
    return {
      texts: "",
      screenWidth: 0,
      bLoading: false,
      arrUserDetail:[]
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  beforeMount() {
    this.texts = FILE.customerTexts[this.selectLanguage];
  },
  methods: {
    close() {
      this.$store.commit("setdialogDetailsCustomerUser", 
      {active: false,
      arr : []});
    },
    getDetailUser(item){
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/employees/${item.sCustomerId}`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
        }
      })
        .then((response) => {
          this.arrUserDetail = response.data.results;
          // this.iNumPages = response.data.iNumPages;
          // this.iTotalItems = response.data.iTotal;
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogDetailsCustomerUser() {
      return this.$store.state.dialogDetailsCustomerUser;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.customerTexts[this.selectLanguage];
      }
    },
    dialogDetailsCustomerUser: function () {
      if (this.dialogDetailsCustomerUser) {
        this.texts = FILE.customerTexts[this.selectLanguage];
        this.getDetailUser(this.$store.state.arrDetailCustomerUser);
      }
    },
  },
};